import '../App.css';
import React from 'react';
import Navigation from '../components/navigation';

function Venue() {
    return (
        <div className="static-background">
            <Navigation></Navigation>
            <h4>Bridgehampton Tennis & Surf Club</h4>
        </div>
    );
}

export default Venue;
