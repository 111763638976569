import '../App.css';
import React from 'react';
import Navigation from '../components/navigation';

function Stay() {
    return (
        <div className='home'>
            <Navigation></Navigation>
            <p> stay page </p>
        </div>
    );
}

export default Stay;
